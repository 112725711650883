export function headerFunc() {
  let headerPage = $('#header').length;
  const header = document.getElementById("header");
  const navBtn = $('#navBtn');
  const navMenu = $('#navMenu');
  let menuOpenFlg = 0;
  let scrollPosition;
  
  if (headerPage) {
    function indexHeaderScrFunc(){
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < 20) {
          header.classList.remove('changeColor');
        } else {
          header.classList.add('changeColor');
        }
      }
    }
    indexHeaderScrFunc();
    $(window).on('scroll', function () {
      indexHeaderScrFunc();
    });
  }
}

export function headerHeightFunc(){
  const header = document.getElementById("header");
  const mainvisual = document.getElementById("mainVisual");

  let resizeTimeout;

  function setMainVisualPadding() {
    const headerHeight = header.offsetHeight;
    mainvisual.style.marginTop = headerHeight + "px";
  }

  window.addEventListener("resize", function() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(setMainVisualPadding, 200);
  });

  setMainVisualPadding();
}

export function headerNavFunc() {
  const navBtn = $('#navBtn');
  const navMenu = $('#navMenu');
  let menuOpenFlg = 0;
  let scrTopFlg = 0;
  let scrollPosition;
  navBtn.on('click', function () {
    navBtn.toggleClass('isOpen');
    if (navBtn.hasClass('isOpen')) {
      scrollPosition = $(window).scrollTop();
      menuOpenFlg = 1;
      navMenu.addClass('isShow');
      header.classList.remove('changeColor');
      $('.headerContents').addClass('isShow');
      $('body').addClass('fixedBody').css({
        'top': -scrollPosition
      });
    } else {
      menuOpenFlg = 0;
      navMenu.removeClass('isShow');
      $('.headerContents').removeClass('isShow');
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
      if (scrTopFlg == 1) {
        header.classList.add('changeColor');
      }
    }
  });
}


export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}