//トップページ　キービジュアルスライダー
export function idxFunc() {
  const indexTop = $("#indexTop").length;

  if(indexTop){

    const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
    wait(500)
      .then(() => {
        $(".mainVisualImg").addClass("isShow")
        return wait(600)
      }).then(() => {
        $(".mainVisualTit").addClass("isShow")
      });

    const faqAccordionBtn = $('.faqList .accordionBtn');
    let resizecheck;
  
    $(window).on('load resize', function () {
      resizecheck = $('#responsibleCheck').width();
    });
      
    faqAccordionBtn.next('dd').css('display', 'none');
    faqAccordionBtn.on('click', function () {
      $(this).next('dd').slideToggle(500);
        if ($(this).hasClass('isOpen')) {
            $(this).removeClass('isOpen');
        } else {
            $(this).addClass('isOpen');
        }
    }); 
  }
}