const contactPage = $('#contactForm').length;

export function contactFunc() {
  if (contactPage) {
    let selectIndex = '';

    // 必須項目名
    const name = $("[name='name']");
    const occupation = $("[name='occupation']");
    const telNumber = $("[name='telNumber']");
    const email = $("[name='email']");
    const emailCheck = $("[name='emailCheck']");
    const otherRequest = $("[name='otherRequest']");
    const agreeCheck = $("[name='agreeCheck']");

    // 必須項目定義
    const onlineRequired = [name, occupation, telNumber, email, emailCheck, otherRequest ,agreeCheck];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      requiredField = onlineRequired;
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    requiredFieldSet();
    // selectControl();


    const agreeCheckTxt = '「個人情報保護方針に同意する」にチェックをしてください。';
    const emailRequiredTxt = '正しいメールアドレスを入力してください。';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります。';
    const telRequiredTxt = '10桁〜12桁の数字を入力してください。';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldDataName = checkField.attr('data-name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("※", "");;
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField == agreeCheck) {
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea').addClass('error').next('.errorTxt').text(agreeCheckTxt);
        } else {
          checkField.parents('.checkboxArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField.hasClass('select')) {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (checkFieldValue == '') {
          checkField.addClass('error').parent('.selectBtn').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').parent('.selectBtn').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'tradingProducts') {
        errorTxt = '取引希望製品は選択必須です。';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です。';
        if (checkFieldName == 'otherRequest') {
          errorTxt = 'お問い合わせ内容は入力必須です。';
        }
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } else if (checkField == emailCheck && checkFieldValue != email.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        }  else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        } else if (checkFieldTag == 'TEXTAREA' && checkFieldValue.length >= 501) {
          errorTxt = '500文字以内で入力してください';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    otherRequest.blur(function () {
      if (otherRequest.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        otherRequest.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        otherRequest.removeClass('error').next('.errorTxt').text('');
      }
    });

    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // is_checked();

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('#header').outerHeight() + 20;
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $(".checkboxArea input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn02 = document.querySelectorAll('.checkBtn02');
    const checkField02 = document.querySelectorAll('.checkField02');
    for (let i = 0; i < checkBtn02.length; i++) {
      if (checkBtn02[i].checked) {
        checkField02[i].disabled = false;
      } else {
        checkField02[i].disabled = true;
        checkField02[i].value = '';
      }
    }
    $(".checkboxArea02 .checkBtn02").on('click', function () {
      for (let i = 0; i < checkBtn02.length; i++) {
        if (checkBtn02[i].checked) {
          checkField02[i].disabled = false;
        } else {
          checkField02[i].disabled = true;
          checkField02[i].value = '';
        }
      }
    });

    var param = location.search;
    if(param == '?type=nagoya'){
      $("[value='名古屋店']").prop('checked',true);
    } else if(param == '?type=osaka'){
      $("[value='大阪店']").prop('checked',true);
    } else if(param == '?type=sapporo'){
      $("[value='札幌店']").prop('checked',true);
    } else if(param == '?type=yokohama'){
      $("[value='横浜店']").prop('checked',true);
    } else if(param == '?type=kyoto'){
      $("[value='京都店']").prop('checked',true);
    }
  }
}
